import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";

export const loginstatuscontext = createContext({
  islogin: false,
  setisLogin: () => {},
});

export const LoginContext = ({ children }) => {
  const [islogin, setisLogin] = useState(false);
  const value = useMemo(() => ({ islogin, setisLogin }), [islogin]);

  return (
    <loginstatuscontext.Provider value={value}>
      {children}
    </loginstatuscontext.Provider>
  );
};

// export default LoginContext;
const defaultAlert = {
  show: false,
  message: "test",
  variant: "primary",
};

export const Alertcontext = createContext({
  Alert: null,
  setAlert: () => {},
});

export const AlertContext = ({ children }) => {
  const [Alert, setAlert] = useState(defaultAlert);
  const value = useMemo(() => ({ Alert, setAlert }), [Alert]);
  // const [first, setFirst] = useState(true);
  // let first = true;
  const first = useRef(true);
  const location = useLocation();
  useEffect(() => {
    if (Alert.show && first.current) {
      // if (location.pathname !== "/") {
      // setFirst(false);
      first.current = false;
    } else {
      setAlert(defaultAlert);
      first.current = true;
    }
  }, [Alert.show, location]);

  return (
    <Alertcontext.Provider value={value}>{children}</Alertcontext.Provider>
  );
};

 export const ForgotPasswordContext  = createContext({
  Message: null,
  setMessage: () => {},
});

export const ForgotPasswordprovider = ({children})=>{
  const [Message, setMessage] = useState("");
  const value = useMemo(() => ({ Message, setMessage }), [Message]);
  return (
    <ForgotPasswordContext.Provider value={value}>{children}</ForgotPasswordContext.Provider>
  );
}
// export default AlertContext;
