import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AlertPopup from "../Alert/alert";
import { IconContext } from "react-icons";
import { FaCheck } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import "./verify.css";

const Verify = () => {
  const { code } = useParams();
  const [owner, setowner] = useState(false);
  const [error, setError] = useState(null);
  const [pin, setpin] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchPin = async () => {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/pin/metadata?code=${code}`
      );
      return await response.json();
    };

    const fetchpin = async (user) => {
      const response = await fetch(
        process.env.REACT_APP_BASEURL + `/collectors/pin/metadata?code=${code}`,
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      return await response.json();
    };

    const fetchdata = async (user) => {
      const aPin = await fetchPin();
      if (!aPin.error) {
        const data = await fetchpin(user);
        if (!data.exists) {
          setError({ message: "You don't own this pin", type: "owner" });
        }

        setpin(aPin);
        console.log(data);
        setowner(data.exists);
      } else {
        setError({ message: "Pin is not active!", type: "active" });
      }
    };

    localStorage.removeItem("previousurl");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      localStorage.setItem("previousurl", location.pathname);
      navigate("/");
    } else {
      fetchdata(user);
    }
  }, [code, location.pathname, navigate]);

  return (
    <div className="container">
      {error && (
        <AlertPopup
          Message={error?.message}
          variant="primary"
          setShow={() => setError(null)}
        ></AlertPopup>
      )}
      {error?.type !== "active" && (
        <div className="container-70">
          <p>
            {pin && (
              <img
                className="verify-pins"
                src={`https://asset.clifty.io/${pin?.artifact?.replace(
                  "ipfs://",
                  "ipfs/"
                )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
                alt="nft"
                width="100%"
              />
            )}
          </p>
          <div className="ownership">
            <span>Ownership Verification: </span>
            {owner ? (
              <IconContext.Provider value={{ color: "green" }}>
                <FaCheck />
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ color: "red" }}>
                <AiOutlineClose />
              </IconContext.Provider>
            )}
          </div>
          <div className="ownership">
            <span>Verified For: </span>
            <span>{JSON.parse(localStorage.getItem("user"))?.username}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Verify;
