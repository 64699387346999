import "./App.css";
import "./style.scss";
import Header from "./component/header/header";
import Footer from "./component/footer/footer";
import Signin from "./component/signin/signin";
import { Route, Routes, useNavigate } from "react-router";
import { useContext, useEffect, useState } from "react";
import PrivateRoute from "./component/Privateroute/privateroute";
import Signup from "./component/signup/signup";
import ForgotPassword from "./component/ForgotPassword/password";
import Terms from "./component/Terms/terms";
import Gallery from "./component/Gallery/gallery";
import Profile from "./component/Profile";
import ChangePassword from "./component/Changepassword/changepassword";
import Privacy from "./component/Privacypolicy/privacy";
import NewPassword from "./component/NewPassword";
import Pindetails from "./component/Pindetails";
import { loginstatuscontext } from "./AppContext";
import Collectpins from "./component/Collectpins/collectpins";
import Showpins from "./component/Showpins/showpins";
import Verify from "./component/Verify/verify";
import Event from "./component/Event/event";
import { Provider, ErrorBoundary } from "@rollbar/react";

// configure Rollbar
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  environment: process.env.REACT_APP_INSTANCE,
};

function TestError() {
  const a = null;
  return a.hello();
}

const Activate = () => {
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const code = params.get("code");

  useEffect(() => {
    console.log(code);
    fetch(`${process.env.REACT_APP_BASEURL}/collectors/activate?code=${code}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        // Navigate to /signin and show a message "Test"
        if (data.activated === true) {
          navigate("/signin", {
            state: {
              error:
                "Congratulations! Your email has been verified. You can now log in to your account.",
            },
          });
        } else {
          console.log(data.reason);
          navigate("/signin", {
            state: { error: data.reason },
          });
        }
      });
  }, [code, navigate]);

  return <></>;
};

function App() {
  const [popupmsg, setPopupmsg] = useState(null);
  const logincontext = useContext(loginstatuscontext);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      logincontext.setisLogin(true);
    }
  }, [logincontext]);

  return (
    <div
      className="pages container-fluid"
      style={{ paddingRight: 0, paddingLeft: 0 }}
    >
      <Header setmsg={setPopupmsg} />
      <div className="route">
        <Routes>
          <Route exact path="/" element={<Signin message={popupmsg} />}></Route>
          <Route
            exact
            path="/signup"
            element={<Signup message={popupmsg} />}
          ></Route>
          <Route
            exact
            path="/signin"
            element={<Signin message={popupmsg} />}
          ></Route>
          <Route
            exact
            path="/user/forgotpassword"
            element={<ForgotPassword />}
          ></Route>
          <Route exact path="/Terms" element={<Terms />}></Route>
          <Route exact path="/Privacy" element={<Privacy />}></Route>

          <Route path="/gallery" element={<PrivateRoute />}>
            <Route path="/gallery" element={<Gallery />}></Route>
          </Route>
          <Route path="/event" element={<PrivateRoute />}>
            <Route path="/event" element={<Event />}></Route>
          </Route>
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="" element={<Profile />}></Route>
            <Route
              path="/profile/changepassword"
              element={<ChangePassword />}
            ></Route>
          </Route>
          <Route exact path="/newpassword" element={<NewPassword />}></Route>
          <Route path="/pins" element={<PrivateRoute />}>
            <Route exact path="/pins/:code" element={<Pindetails />}></Route>
          </Route>
          <Route path="/qrs/:code" element={<Collectpins />} />
          <Route path="/activate" element={<Activate />}></Route>
          <Route path="/show/:code" element={<Showpins />} />
          <Route path="/verify/:code" element={<Verify />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
