import React from "react";

const event = () => {
  return (
    <div>
      <>
        <h1 style={{ textAlign: "center" }}>Events</h1>
        <div>
          <h3>Active Events</h3>
          <div className="contents">
            <div style={{ display: "flex", flexDirection: "column" }}></div>
          </div>
        </div>
      </>
    </div>
  );
};

export default event;
