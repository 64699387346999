import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";

const contracturl = {
    AMOY: "https://amoy.polygonscan.com/address/0x60109649c4a94736b11c09b921da365322df5877",
    POLYGON:
        "https://polygonscan.com/address/0x60109649c4A94736b11c09B921dA365322Df5877",
};

const defaultdetails = {
    exists: false,
    id: 0,
    tokennumber: 0,
    name: "",
    description: "",
    mintnetwork: "",
    contract: "",
    owner: 0,
    location: "",
    artifact: "",
    extra: {
        attributes: [],
    },
    timestampfrom: "",
    timestampto: "",
    email: "",
    public_key: "",
    metadata: "",
    txhash: "",
};

const Showpins = () => {
    const [setError] = useState();
    const [details, setdetails] = useState(defaultdetails);
    const { code } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASEURL}/nft/metadata?code=${code}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.error) {
                    setError("No pin associated with this id");
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                } else setdetails(data);
            });
    }, [code, navigate, setError]);

    return (
        <div className="container collectpinstemplate">
            <CustomAccordion
                title={details.company}
                className="title container-70">
                <>
                    <h1>{details.name}</h1>

                    <p>
                        <img
                            // className="pin-image"
                            src={`https://asset.clifty.io/${details.artifact.replace(
                                "ipfs://",
                                "ipfs/"
                            )}?pinataGatewayToken=Jj_K5bQqbTXNlJhh6FG9tf31rZP5XPWSGRGEf01BL4S9Ck8EZKABX6nZ-PwyNHf9`}
                            alt="nft"
                            width="100%"
                        />
                    </p>
                </>
            </CustomAccordion>

            <CustomAccordion
                title="Description"
                className="description container-70">
                <>
                    <p className="">{details.description}</p>
                </>
            </CustomAccordion>

            <CustomAccordion
                title="Properties"
                className="properties container-70">
                <ul className="list-group list-group-flush">
                    {details.extra.attributes.map((attribute) => {
                        return (
                            <li
                                key={attribute["trait_type"]}
                                className="list-group-item">
                                <span className="fw-bold text-uppercase">
                                    {attribute["trait_type"]}:{" "}
                                </span>

                                <span>{attribute["value"]}</span>
                            </li>
                        );
                    })}
                </ul>
            </CustomAccordion>
            <CustomAccordion title="NFT Details" className="nft container-70">
                <ul className="list-group list-group-flush">
                    <li id="idItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">Token Unique ID: </span>

                        <span>{code}</span>
                    </li>
                    <li id="networkItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">
                            Network:{" "}
                        </span>

                        <span>{details.mintnetwork}</span>
                    </li>
                    <li id="typeItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">Type: </span>

                        <span>ERC-721</span>
                    </li>
                    <li id="contractItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">
                            Contract:{" "}
                        </span>

                        <Link to={contracturl[details.mintnetwork]} target="_blank">
                            Details
                        </Link>
                    </li>
                    <li id="typeItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">Token #: </span>

                        <span>{details.tokennumber}</span>
                    </li>
                    <li id="typeItem" className="list-group-item">
                        <span className="fw-bold text-uppercase">Owner: </span>

                        <span>{details.public_key}</span>
                    </li>
                </ul>
            </CustomAccordion>

            <br />
            <br />
        </div>
        // </div>
    );
};

export default Showpins;

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log("totally custom!")
    );

    return (
        <button type="button" className="btn" onClick={decoratedOnClick}>
            {children}
        </button>
    );
}

function CustomAccordion({ title, children, key, ...otherprops }) {
    return (
        <Accordion defaultActiveKey={key} {...otherprops}>
            <Card>
                <Card.Header
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}>
                    <p>{title}</p>
                    <CustomToggle eventKey={key}>+</CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey={key}>
                    <Card.Body>{children}</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
