import { useContext, useEffect, useState } from "react";
import { Alertcontext, ForgotPasswordContext } from "../../AppContext";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";

const ForgotPassword = () => {
    const alertContext = useContext(Alertcontext);
    const forgotPasswordcontext = useContext(ForgotPasswordContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState();
    const [error, setError] = useState("");
    
    useEffect(()=>{
        setError(forgotPasswordcontext.Message)
    },[forgotPasswordcontext.Message])

    const Resetpassword = async () => {
        try {
            const res = await fetch(
                `${process.env.REACT_APP_BASEURL}/collectors/resetpassword?email=${encodeURIComponent(
                    email
                )}`,
                {
                    method: "GET",
                    headers: { "content-type": "application/json" },
                }
            );
            const data = await res.json();
            if (data["error"]) {
                setError(data["error"]);
            } else {
                alertContext.setAlert({
                    show: true,
                    variant: "primary",
                    message:
                        "Password reset instructions were sent. please check your email and continue.",
                });

                navigate("/");
                // const user = await getcode();
                // console.log(user);
                // navigate(`/newpassword?code=${user.code}`);
            }
        } catch (error) {
            setError("server Error");
        }
    };

    return (
        <div className="col-md-2 col-lg-4 mx-auto">
            {error && (
                <AlertPopup
                    Message={error}
                    variant="danger"
                    setShow={() => setError(null)}
                />
            )}
            <h3 className="login-heading mb-4">Forgot Password</h3>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-floating mb-3">
                    <input
                        className="form-control"
                        type="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <label htmlFor="email">Email address</label>
                </div>
                <div className="d-grid">
                    <button
                        className="btn btn-lg btn-primary btn-login text-uppercase fw-bold"
                        style={{ fontSize: "16px" }}
                        type="button"
                        onClick={Resetpassword}>
                        Reset Password
                    </button>
                </div>
            </form>
            <br />
            <br />
            <div style={{ textAlign: "center" }}>
                Back to <a href="/signin">Sign in</a> or{" "}
                <a href="/signup">Sign up</a>
            </div>
        </div>
    );
};

export default ForgotPassword;
