import { useContext, useState } from "react";
import { Alertcontext, loginstatuscontext } from "../../AppContext";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";

const ChangePassword = () => {
  const alertContext = useContext(Alertcontext);
  const logincontext = useContext(loginstatuscontext);
  const navigate = useNavigate();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();

  const changePassword = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return navigate("/");
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const res = await fetch(process.env.REACT_APP_BASEURL + "/collectors/changepass", {
        method: "post",
        headers: {
          Authorization: `Bearer ${user.sessionToken}`,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });
      const data = await res.json();
      if (data?.success) {
        alertContext.setAlert({
          show: true,
          variant: "primary",
          message: "Password changed successfuly",
        });

        localStorage.removeItem("user");
        logincontext.islogin = false;
        navigate("/");
        return;
      }
    } catch (err) {
      const errorobj = {
        show: true,
        variant: "danger",
      };

      if (err.toString()?.includes("Forbidden")) {
        alertContext.setAlert({
          ...errorobj,
          message: "Session expired, please sign in",
        });

        localStorage.removeItem("user");
        navigate("/");
      } else {
        alertContext.setAlert({
          ...errorobj,
          message: "Error resetting password",
        });
      }
      return;
    }
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          Message={error}
          variant="danger"
          setShow={() => setError(null)}
        />
      )}
      <form>
        <table border="0">
          <tbody>
            <tr>
              <td>Password:</td>
              <td>
                <input
                  type="password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />{" "}
              </td>
              <td>*</td>
            </tr>
            <tr>
              <td>Password confirmation:</td>
              <td>
                <input
                  type="password"
                  required
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />

                <button
                  className="btn btn-primary"
                  type="button"
                  style={{ fontWeight: 500 }}
                  onClick={() => changePassword()}
                >
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default ChangePassword;
